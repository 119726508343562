import $ from 'jquery';

import './sass/style.scss';
import './js/app.js';

$(document).on('change', '#select_all', function () {
    if ($(this).is(':checked')) {
        $(this).parent().parent().find('input[type=checkbox]').prop('checked', true);
    } else {
        $(this).parent().parent().find('input[type=checkbox]').prop('checked', false);
    }
});

$('#show_coupon_form').on('click', () => {
    $('#coupon_form').slideToggle();
});

$('#go-to-home-btn').on('click', () => {
    window.location.href = 'https://radamed.pl';
});
